html, body {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  background: #0B212A;
  overscroll-behavior: none;
}

body.stop-scroll, body.stop-scroll-w, body.menu-stop-scroll {
  overflow: hidden;
}

.all-website-wrapper {
  opacity: 0;
  font-family: theme('fontFamily.sans');
  @include transition-fx;
  &.loaded {
    opacity: 1;
  }
}

.unexpected {
  &.title-w-em {
    h1, h2, h3, h4, h5 {
      line-height: 115%;
    }
    p {
      margin-top: 20px;
    }
  }
}


// Custom container component && spacings
.container, .lg\:container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  @screen 2xl {
    $map: (
      1536px: 1312px,
      1728px: 1499px,
      1920px: 1666px,
      2220px: 1926px,
      2560px: 2221px
    );
    @include fs('max-width', $map);
  }
}

// Btns
a.link-button {
  position: relative;
  padding-bottom: 12px;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: white;
    opacity: .3;
  }
}
a.btn-header-test, a.btn-normal-test {
  .bottom { z-index: 1; }
  .top {
    position: relative;
    z-index: 2;
  }
}
a.cta-light-2, div.cta-light-2 {
  .text {
    position: relative;
    &:before {
      content: '';
      width: 0;
      height: 1px;
      position: absolute;
      left: 0;
      bottom: -5px;
      background-color: theme('colors.action');
      @include transition-fx;
    }
  }
  &:hover {
    .text:before {
      width: 100%;
    }
  }
}


//Recaptcha hide
.grecaptcha-badge {
  display: none;
}

// scrollbar-hide
/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

// swiper
.swiper-pagination-bullets {
  display: flex;
  align-items: center;
  justify-content: center;
  .swiper-pagination-bullet {
    width: 7px;
    height: 7px;
    border: 1px solid white;
    border-radius: 50%;
    opacity: .3;
    transition: all ease .2s;
    &:not(:last-child) {
      margin-right: 10px;
    }
    &.swiper-pagination-bullet-active {
      background: white;
      opacity: 1;
    }
  }
}

// mapbox
.mapboxgl-control-container {
  display: none;
}

// rounded icon
.rounded-icon {
  flex-basis: 55px;
  @screen 3xl {
    $map: (
      1728px: 55px,
      1920px: 61px,
      2220px: 70px,
      2560px: 80px
    );
    @include fs('flex-basis', $map);
  }
}

.buy-button-wrapper {
  .shopify-buy-frame {
    margin-inline: auto;
    button.shopify-buy__btn {
      white-space: nowrap;
    }
  }
}
